<template>
  <span>
    <v-chip
      v-for="status in allStatus"
      :key="status"
      :color="'bg-light-' + getStatusColor(status)"
      :class="'mx-2 text-' + getStatusColor(status)"
      v-b-tooltip.hover
      :title="getStatusName(status)"
    >
      {{ getCountByStatus(status) }}
    </v-chip>
  </span>
</template>

<script>
import i18nDiclinaService from "@/core/services/diclina_i18n";

export default {
  name: "StatusNumberChips",
  props: {
    allStatus: {
      type: Array,
      required: true,
    },
    statusName: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getCountByStatus(status) {
      return this.items.filter((item) => item === status).length;
    },
    getStatusColor(status) {
      return (
        i18nDiclinaService.getDiclinaStatusStyle(this.statusName, status)
          ?.color || ""
      );
    },
    getStatusName(status) {
      return i18nDiclinaService.getDiclinaStatusName(this.statusName, status);
    },
  },
};
</script>

<style scoped></style>
