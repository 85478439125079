import store from "../../store/index";
import AddressFilterService from "../../addressfilter.service";
// import MarkerClusterer from "@googlemaps/markerclustererplus";
import MarkerClusterer from "./pie-markercluster";
import ColorService from "../../colors/color.service";
import i18nDiclinaService from "../../diclina_i18n";

import Vue from "vue";
import PieClusterMarker from "../../../../view/pages/addresses/maps/PieClusterMarker";
import GMapInfoWindow from "../../../../view/components/GMapInfoWindow";

const zoomThreshold = 17;

let marker = [];
let clusterMarkerer = null;
let google = null;
let map = null;
let header = [];

const markerMapView = {
  name: "marker",
  initMarker: async () => {
    const locations = await store.getters.addresses();

    if (google && locations) {
      const zoom = map.getZoom();
      const isSingleStatusFilter = store.getters.statusGroupFilterCount() === 1;
      const firstStatusGroupKey = store.getters.firstStatusGroupKey();
      // const status = store.getters.status.meta.overall;

      locations.forEach(location => {
        if (AddressFilterService.filterAddress(location)) {
          store.dispatch("addVisibleAddress", location);

          const point = new google.maps.LatLng(
            location.point.lat,
            location.point.lng
          );

          let label = "";
          let styleName = "blue";

          if (isSingleStatusFilter) {
            if (firstStatusGroupKey === "product") {
              // label = i18nDiclinaService.getDiclinaStatusName(
              //   "product",
              //   store.getters.filter.status.product
              // );
              label = store.getters.filter.status.product;
              styleName = i18nDiclinaService.getDiclinaStatusStyle(
                "product",
                store.getters.filter.status.product
              )?.color || "";
            } else {
              label = location.status[firstStatusGroupKey];
              styleName = i18nDiclinaService.getDiclinaStatusStyle(
                  firstStatusGroupKey,
                  location.status[firstStatusGroupKey]
              )?.color || "";
            }
          }

          const newMarker = createMarker(
            point,
            label,
            location,
            styleName,
            map
          );

          newMarker.setMap(map);
          newMarker.setVisible(zoom > zoomThreshold);

          marker.push(newMarker);
        }
      });

      if (zoom <= zoomThreshold) {
        clusterMarkerer = await createMarkerClusterer();
      }
    }
  },
  resetMarker: async () => {
    for (let gmarker of marker) {
      gmarker.setMap(null);
      gmarker = null;
    }

    if (clusterMarkerer) {
      clusterMarkerer.clearMarkers();
    }
    clusterMarkerer = null;

    marker.length = 0;

    await store.dispatch("clearVisibleAddresses").then(() => {});
  },
  setGoogle: googleObj => (google = googleObj),
  setMap: mapObj => (map = mapObj),
  setHeader: headerObj => (header = headerObj)
};

const createMarker = (position, title, location, style, map = null) => {
  const icon = require("../../../../../public/media/marker/Marker_" +
    style +
    ".png");

  let marker = new google.maps.Marker({
    position,
    map,
    shadow: null,
    icon,
    shape: null,
    title,
    zIndex: Math.round(position.lat() * -100000) << 5
  });

  let infowindow = new google.maps.InfoWindow();

  google.maps.event.addListener(marker, "click", function() {
    infowindow.setContent(createInfoWindow(location));
    infowindow.open(map, marker);
  });

  return marker;
};

const createInfoWindow = location => {
  const statusGroups = store.getters.statusGroups;
  const salesPersons = store.getters.salesPersons;

  let InfoWindow = Vue.extend(GMapInfoWindow);
  let instance = new InfoWindow({
    propsData: {
      location,
      headers: header,
      statusGroups,
      salesPersons
    }
  });

  instance.$mount();

  return instance.$el;
};

const createMarkerClusterer = async () => {
  let opt = {
    gridSize: 100,
    styles: [
      { height: 35, width: 35 },
      { height: 40, width: 40 },
      { height: 45, width: 45 },
      { height: 50, width: 50 },
      { height: 60, width: 60 }
    ],
    status: [],
    callbackOnAdd: createPieClusterMarker
  };

  google.load("visualization", "1.0", { packages: ["corechart", "piechart"] });
  window.google = google;
  return new MarkerClusterer(map, marker, opt);
};

const createPieClusterMarker = (markers, size) => {
  if (markers.length > 1) {
    let ComponentClass = Vue.extend(PieClusterMarker);

    let sections = [];

    if (store.getters.statusGroupFilterCount() === 1) {
      const firstStatusGroupKey = store.getters.firstStatusGroupKey();
      const status = store.getters.status;

      const labels = status.meta.overall[firstStatusGroupKey].i18n.de_DE;

      // this part of the code replaces the string "status_" by an empty string in the various names of the status
      // some status contain the string "status_" in diclina, if this part gets changed/removed you need to fix the status name matching in core/config/i18n/diclina.js
      // eslint-disable-next-line no-unused-vars
      for (let [key, unused] of Object.entries(labels)) {
        const value =
          (markers.filter(obj => obj.title === key.replace("status_", ""))
            .length /
            markers.length) *
          100;

        const label =
          i18nDiclinaService.getDiclinaStatusGroupName(firstStatusGroupKey) +
          ": " +
          i18nDiclinaService.getDiclinaStatusName(
            firstStatusGroupKey,
            key.replace("status_", "")
          );

        const colorName = i18nDiclinaService.getDiclinaStatusStyle(
          firstStatusGroupKey,
          key.replace("status_", "")
        )?.color || "";

        sections.push({
          label,
          value,
          color: ColorService.getTransparentColorByName(colorName, 0.6)
        });
      }
    } else {
      sections = [
        {
          label: "Gesamt",
          value: 100,
          color: ColorService.getTransparentColorByName("blue", 0.6)
        }
      ];
    }

    let instance = new ComponentClass({
      propsData: { count: markers.length, size, sections }
    });
    instance.$mount();

    return instance.$el;
  } else {
    return document.createElement("DIV");
  }
};

export default markerMapView;
