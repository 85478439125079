<template>
  <div style="width: 450px">
    <h4>Allgemeine Informationen</h4>
    <table class="table table-borderless">
      <template v-for="(header, index) in generalHeaders">
        <template>
          <tr :key="index">
            <th>{{ header.text }}</th>
            <td v-if="header.value === 'address.personId'">
              <sales-person-avatar
                :sales-person="getSalesPerson(location.address.personId)"
                :size="30"
                color="secondary"
              />
              {{ getSalesPersonName(location.address.personId) }}
            </td>
            <td v-if="header.value === 'address.housingIndustry'">
              <span v-if="location.address.housingIndustry">
                {{ location.address.housingIndustry.label }}
              </span>
              <span v-else>Nicht Zugewiesen</span>
            </td>
            <td v-else>{{ getLocationData(header.value) }}</td>
          </tr>
        </template>
      </template>
      <tr v-for="statusGroup in localStatusGroups" :key="statusGroup.value.key">
        <template v-if="statusGroup.value.key === 'offerWE'">
          <th>{{ statusGroup.text }}</th>
          <td>
            <StatusNumberChips
              :items="location.address.apartments.map((x) => x.offer)"
              status-name="offer"
              :all-status="
                i18nDiclinaService.getDiclinaStatusGroupKeys('offer')
              "
            />
          </td>
        </template>
        <template v-else>
          <th>{{ getStatusGroupName(statusGroup) }}</th>
          <td v-if="statusGroup.value.key === 'product'">
            <status-product-chips :location="location" />
          </td>

          <td v-else>
            <status-chip
              :status-group="statusGroup.value.key"
              :status="
                Array.isArray(location.status[statusGroup.value.key])
                  ? location.status[statusGroup.value.key][0]
                  : location.status[statusGroup.value.key]
              "
            />
          </td>
        </template>
      </tr>
    </table>

    <div v-if="can('ROLE_DSN_READ_CUSTOMER')">
      <h4>Kunden dieser Adresse</h4>
      <table class="table">
        <tr>
          <th>Kundennummer</th>
          <th v-if="can('ROLE_DSN_READ_CUSTOMER_NAME')">Name</th>
          <th>Produktstatus</th>
        </tr>

        <tr v-for="customer in location.customers" :key="customer.customer">
          <td class="font-weight-bold">{{ customer.clientId }}</td>
          <td v-if="can('ROLE_DSN_READ_CUSTOMER_NAME')">
            {{ customer.firstName }} {{ customer.lastName }}
          </td>
          <td>
            <status-chip
              status-group="product"
              :status="customer.status.product"
            />
          </td>
        </tr>
      </table>
    </div>

    <div>
      <h4>Vertriebsstatus</h4>
      <table class="table">
        <tr>
          <th>Stockwerk</th>
          <th>Lage</th>
          <th>Status</th>
          <th>Nachname letzter bekannter Mieter</th>
          <th>Kunde</th>
        </tr>
        <tr
          :key="apartment.id"
          v-for="apartment in location.address.apartments"
        >
          <td style="vertical-align: middle">{{ apartment.floor }}</td>
          <td style="vertical-align: middle">
            {{ apartment.locationInfo }}
          </td>
          <td>
            <status-chip status-group="offer" :status="apartment.offer" />
          </td>
          <td style="vertical-align: middle">
            {{ apartment.lastnameOfLastKnownTenant }}
          </td>
          <td style="vertical-align: middle">
            {{ apartment.customerId ? "Ja" : "Nein" }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import lodash from "lodash";
import i18nDiclinaService from "../../core/services/diclina_i18n";
import StatusChip from "./StatusChip";
import SalesPersonAvatar from "./SalesPersonAvatar";
import StatusProductChips from "./StatusProductChips";
import StatusNumberChips from "@/view/components/StatusNumberChip.vue";
import clinaService from "../../core/services/diclina_i18n";

export default {
  name: "GMapInfoWindow",
  components: {
    StatusNumberChips,
    StatusProductChips,
    SalesPersonAvatar,
    StatusChip,
  },
  props: {
    location: {
      type: Object,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    statusGroups: {
      type: Array,
      required: true,
    },
    salesPersons: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localStatusGroups: [],
    };
  },
  created() {
    let localStatusGroups = [...this.statusGroups];
    const index = localStatusGroups.findIndex(
      (group) => group.value.key === "offer"
    );
    if (index !== -1) {
      localStatusGroups.splice(index + 1, 0, {
        text: "Vertriebsstatus WE",
        value: {
          key: "offerWE",
          statusGroup: localStatusGroups[index].value.statusGroup,
        },
      });
    }
    this.localStatusGroups = localStatusGroups;
  },
  computed: {
    i18nDiclinaService() {
      return i18nDiclinaService;
    },
    clinaService() {
      return clinaService;
    },
    statusHeaders() {
      return this.headers.filter((obj) => {
        return obj.value.startsWith("status.");
      });
    },
    generalHeaders() {
      return this.headers.filter((obj) => {
        return !obj.value.startsWith("status.");
      });
    },
  },
  methods: {
    getLocationData(attrKey) {
      return lodash.get(this.location, attrKey) || " - ";
    },

    getStatusGroupName(statusGroup) {
      return statusGroup.value.key === "offer"
        ? "Vertriebsstatus"
        : i18nDiclinaService.getDiclinaStatusGroupName(statusGroup.value.key);
    },

    getStatusName(statusGroup) {
      return i18nDiclinaService.getDiclinaStatusName(
        statusGroup.value.key,
        this.location.status[statusGroup.value.key]
      );
    },

    getSalesPerson(salesPersonId) {
      return this.salesPersons.find(
        (person) => parseInt(person.id) === parseInt(salesPersonId)
      );
    },

    getSalesPersonName(salesPersonId) {
      const salesPerson = this.getSalesPerson(salesPersonId);
      return salesPerson.firstName + " " + salesPerson.lastName;
    },
  },
};
</script>

<style scoped></style>
